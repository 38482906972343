<template>
  <div>
    <div>
      <k-header-section
        header-text="Wallet Rules"
        :item-label="`${ruleTypeText()}s`"
        :sub-nav-bar-items="navDatas"
        :total-items-count="totalItemsCount"
        :current-page-count="currentPageCount"
      >
        <div class="d-flex justify-content-between inter-500-14">
          <k-button
            variant="info"
            @click="onSetCreateWalletRules"
          >
            <kingpin-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="ps-2 text-uppercase">{{ ruleTypeText().toUpperCase() }}</span>
          </k-button>
        </div>
      </k-header-section>
    </div>
    <div class="table-padding">
      <div class="filter-sorting-container">
        <k-search-bar-section
          placeholder="Search By Brand, Retailer..."
          hide-sort-option
          hide-filter-option
          hide-column-edit-option
          @on-search="updateSearch"
        />
        <multiple-filter
          class="ms-2"
          :filter-fields="filterFields"
          @on-apply-filter="onApplyFilter"
        />
        <k-sorting
          class="ms-auto"
          hide-column-edit-option
          :demo-checkbox-label="`${ showDemoData ? 'Hide':'Show'} Demo ${ruleTypeText(true)}s`"
          :self-managed-checkbox-label="`${ showSelfManagedData ? 'Hide':'Show'} Direct ${ruleTypeText(true)}s`"
          :sort-items="sortFields"
          show-self-managed-checkbox
          show-demo-checkbox
          @update-sort="$root.$refs.walletTable.updateSort($event)"
          @update-sort-direction="$root.$refs.walletTable.updateSortDirection($event)"
          @update-demo-filter="updateDemoFilter"
          @update-self-managed-filter="updateSelfManagedFilter"
        />
      </div>
      <router-view
        :key="tableKey"
        :sidebar-display="headerColumnEdit"
        :loading="loading"
        :wallet-rules="walletRules"
        :search-text="searchText"
        @sidebar-display="toggleHeaderColumnEdit"
        @update-total-items="updateTotalItems"
        @load-wallet-rules="initPageContent"
      />
    </div>
  </div>
</template>

<script>
import {
  KHeaderSection, KSearchBarSection, KButton, KingpinIcon,
} from '@kingpin-global/kingpin-ui'
// Nav Bar content from json file
import walletRulesNavBarContent from '@/assets/app-content/wallet-navbar-content.json'
import {
  SET_COMPONENT,
  SET_FORM_ACTIONS,
  SET_TITLE,
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { FETCH_BRANDS_USERS, FETCH_RETAILER_USERS, ROUTES } from '@/constants'
import { FETCH_WALLET_RULES } from '@/store/modules/wallet.module'
import store from '@/store'
import { walletMixin } from '@/mixins/wallet-mixin'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { apiToastWarning } from '@/@core/utils/toast'
import CreateWalletRule from './CreateWalletRule.vue'

export default {
  name: 'WalletRules',
  components: {
    KHeaderSection,
    KSearchBarSection,
    KButton,
    KingpinIcon,
  },
  mixins: [walletMixin, KSearchAndSortMixin],
  data() {
    return {
      headerColumnEdit: false,
      navDatas: walletRulesNavBarContent.navBarItems,
      tableKey: 1,
      currentPageCount: 0,
      totalItemsCount: 0,
      walletRules: {},
      loading: true,
      filterMetaArray: [],
      sortFields: [],
      showDemoData: false,
      showSelfManagedData: false,
      searchText: '',
    }
  },
  computed: {
    isCreateEnabled() {
      return ![ROUTES.WALLET_RULES.CHILDREN.PENDING_RULES.name].includes(this.$route.name)
    },
  },
  async created() {
    if (!store.getters.retailers.length) {
      await store.dispatch(FETCH_RETAILER_USERS)
    }
    if (!store.getters.brands.length) {
      await store.dispatch(FETCH_BRANDS_USERS)
    }
    await this.initPageContent()
  },
  methods: {
    ruleTypeText(isDemo = false) {
      if (this.isBrandTerms) {
        return 'Brand Rule'
      }
      if (this.isRetailerTerms) {
        return 'Retailer Rule'
      }
      if (this.isBrandVsRetailerTerms) {
        if (isDemo) {
          return 'Brand vs Demo Retailer Rule'
        }
        return 'Brand vs Retailer Rule'
      }
      return 'In Review Rule'
    },
    setSortFields(fields = []) {
      this.sortFields = fields
    },
    onSetCreateWalletRules() {
      store.commit(SET_TITLE, `Create ${this.ruleTypeText()}`)
      store.commit(SET_COMPONENT, CreateWalletRule)
      store.commit(SET_FORM_ACTIONS, this.formActions)
      this.toggleSidebar()
    },
    toggleSidebar() {
      store.commit(TOGGLE_SIDEBAR)
    },
    toggleHeaderColumnEdit() {
      this.headerColumnEdit = !this.headerColumnEdit
    },
    formActions(actiontype) {
      switch (actiontype) {
        case 'close': {
          this.toggleSidebar()
          break
        }
        case 'cancel': {
          this.toggleSidebar()
          break
        }
        case 'success': {
          this.initPageContent()
          this.toggleSidebar()
          break
        }
        default: {
          break
        }
      }
    },
    updateTotalItems(currentPageCount, totalItemsCount) {
      this.currentPageCount = currentPageCount
      this.totalItemsCount = totalItemsCount
      const tableFields = this.$root.$refs.walletTable?.tableFields
      this.setSortFields(tableFields)
      this.setFilterFields(this.filterMetaArray, tableFields)
    },
    async initPageContent() {
      const queryParams = {
        isDemo: this.showDemoData,
        isSelfManaged: this.showSelfManagedData,
      }
      if (Object.values(this.filterQuery).length) {
        queryParams.filter = JSON.stringify(this.filterQuery)
      }
      await store
        .dispatch(FETCH_WALLET_RULES, queryParams)
        .then(res => {
          const resData = res.data.data
          this.walletRules = resData
          this.tableKey++
          this.filterMetaArray = resData.filter
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          apiToastWarning(err)
        })
    },
    updateSearch(searchingText = '') {
      this.searchText = searchingText
    },
    updateDemoFilter(value) {
      this.showDemoData = value
      this.initPageContent()
    },
    updateSelfManagedFilter(value) {
      this.showSelfManagedData = value
      this.initPageContent()
    },
  },
}
</script>
