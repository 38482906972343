import { ROUTES } from '@/constants'

export const walletMixin = {
  computed: {
    isBrandTerms() {
      return this.$route.name === ROUTES.WALLET_RULES.CHILDREN.BRAND_RULES.name
    },
    isRetailerTerms() {
      return this.$route.name === ROUTES.WALLET_RULES.CHILDREN.RETAILER_RULES.name
    },
    isBrandVsRetailerTerms() {
      return this.$route.name === ROUTES.WALLET_RULES.CHILDREN.BRAND_VS_RETAILER_RULES.name
    },
    isPendingTerms() {
      return this.$route.name === ROUTES.WALLET_RULES.CHILDREN.PENDING_RULES.name
    },
  },
  methods: {
    getPriceBookName(priceBookId) {
      const priceBook = this.priceBooks.find(_ => _._id === priceBookId)
      if (!priceBook) {
        return priceBookId
      }
      return `${priceBook.priceBookName} - ${priceBook.collectionName}`
    },
  },
}
