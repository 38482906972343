<template>
  <div class="create-wallet-padding">
    <validation-observer
      #default="{invalid}"
    >
      <div class="form-block">
        <k-form-group v-if="!isBrandTerms">
          <template #label>
            <span> Retailer <span class="text-danger">*</span> </span>
          </template>
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{'has-value': !!selectedRetailer.id}"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="selectedRetailer.id ?
              `${selectedRetailer.name} (${selectedRetailer.email})` : 'Select Retailer'"
          >
            <b-dropdown-form>
              <k-form-input
                v-model="searchRetailer"
                type="text"
                placeholder="Search for a Retailer"
              />
              <feather-icon
                icon="SearchIcon"
                class="drop-search-icon"
                size="22"
              />
            </b-dropdown-form>
            <template v-if="filteredRetailers.length">
              <b-dropdown-item
                v-for="retailer in filteredRetailers"
                :key="retailer.id"
                :active="selectedRetailer.id === retailer.id"
                @click="selectedRetailer = retailer"
              >
                {{ retailer.name }} ({{ retailer.email }})
              </b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item disabled>
                No matching fields
              </b-dropdown-item>
            </template>
          </b-dropdown>
          <small
            v-if="submit && !selectedRetailer.id"
            class="text-danger"
          > Please select the retailer </small>
        </k-form-group>
        <k-form-group
          v-if="!isRetailerTerms"
          class="mt-2"
        >
          <template #label>
            <span> Brand <span class="text-danger">*</span> </span>
          </template>
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{'has-value': !!selectedBrand.id}"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="selectedBrand.id ?
              `${selectedBrand.name} (${selectedBrand.email})` : 'Select Brand'"
          >
            <b-dropdown-form>
              <k-form-input
                v-model="searchBrand"
                type="text"
                placeholder="Search for a Brand"
              />
              <feather-icon
                icon="SearchIcon"
                class="drop-search-icon"
                size="22"
              />
            </b-dropdown-form>
            <template v-if="filteredBrands.length">
              <b-dropdown-item
                v-for="brand in filteredBrands"
                :key="brand.id"
                :active="selectedBrand.id === brand.id"
                @click="onSelectBrand(brand)"
              >
                {{ brand.name }} ({{ brand.email }})
              </b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-item disabled>
                No matching fields
              </b-dropdown-item>
            </template>
          </b-dropdown>
          <small
            v-if="submit && !selectedBrand.id"
            class="text-danger"
          > Please select the brand </small>
        </k-form-group>
        <validation-provider
          v-if="!isRetailerTerms"
          #default="{ errors }"
          name="Commercial Terms"
          vid="commercial"
          rules="required|between:0,100"
        >
          <k-form-group
            class="mt-2"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <template #label>
              <span>
                Commercial Terms <span class="text-danger">*</span>
              </span>
            </template>
            <k-form-input
              v-model="commercialTerms"
              type="number"
              name="commercial"
              placeholder="25"
            />
          </k-form-group>
        </validation-provider>
        <k-form-group
          class="mt-2"
        >
          <template #label>
            <span>
              Payment Terms <span class="text-danger">*</span>
            </span>
          </template>
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{'has-value': !!selectedPaymentTerms}"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="selectedPaymentTerms ? selectedPaymentTerms : 'Select Payment Terms'"
          >
            <b-dropdown-item
              v-for="terms in Object.values(PAYMENT_TERMS).map((_) => _.TEXT)"
              :key="terms"
              :active="selectedPaymentTerms === terms"
              @click="selectedPaymentTerms = terms"
            >
              {{ terms }}
            </b-dropdown-item>
          </b-dropdown>
          <small
            v-if="submit && !selectedPaymentTerms"
            class="text-danger"
          > Please select payment terms </small>
        </k-form-group>
        <validation-provider
          v-if="isBrandVsRetailerTerms"
          #default="{ errors }"
          name="Price Multiplier"
          vid="priceMultiplier"
          rules="required|between:0.1,1.8"
        >
          <k-form-group
            class="mt-2"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <template #label>
              <span>
                Price Multiplier <span class="text-danger">*</span>
              </span>
            </template>
            <k-form-input
              v-model="priceMultiplier"
              type="number"
              name="priceMultiplier"
              placeholder="0.1 - 1.8"
            />
          </k-form-group>
        </validation-provider>
        <k-form-group
          class="mt-2"
        >
          <template #label>
            <span>
              Logistics Terms <span class="text-danger">*</span>
            </span>
          </template>
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{'has-value': !!selectedLogisticsTerms}"
            variant="none"
            toggle-class="drop-caret-icon"
            :text=" selectedLogisticsTerms ?
              LOGISTICS_TERMS[selectedLogisticsTerms] : 'Select Logistics Terms'"
          >
            <b-dropdown-item
              v-for="logistics in Object.keys(LOGISTICS_TERMS)"
              :key="logistics"
              :active="selectedLogisticsTerms === logistics"
              @click="selectedLogisticsTerms = logistics"
            >
              {{ LOGISTICS_TERMS[logistics] }}
            </b-dropdown-item>
          </b-dropdown>
          <small
            v-if="submit && !selectedLogisticsTerms"
            class="text-danger"
          > Please select logistics terms </small>
        </k-form-group>
        <k-form-group
          v-if="isBrandVsRetailerTerms"
          class="mt-2"
        >
          <template #label>
            <span>
              Price Book
            </span>
          </template>
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{'has-value': !!priceBookId}"
            variant="none"
            toggle-class="drop-caret-icon"
            :text=" priceBookId ?
              getPriceBookName(priceBookId) : 'Select Price Book'"
          >
            <template v-if="priceBooks.length">
              <b-dropdown-item
                v-for="priceBook in priceBooks"
                :key="priceBook._id"
                :active="priceBookId === priceBook._id"
                @click="priceBookId = priceBook._id"
              >
                {{ `${priceBook.priceBookName} - ${priceBook.collectionName}` }}
              </b-dropdown-item>
            </template>
            <b-dropdown-item
              v-else
              disabled
            >
              No price book available
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
        <validation-provider
          #default="{ errors }"
          name="Commercial Terms"
          :vid="isBrandVsRetailerTerms ? 'Account Type' : 'Applied To'"
          rules="required"
        >
          <k-form-group
            class="mt-2"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <template #label>
              <span>
                {{ isBrandVsRetailerTerms ? "Account Type" : "Applied To" }}<span class="text-danger">*</span>
              </span>
            </template>
            <div class="radio-block">
              <b-form-radio
                v-model="selectedOrderCount"
                value="0"
                class="form-radio text-nowrap me-5 "
              > {{ isBrandVsRetailerTerms ? 'New': 'New Orders' }} </b-form-radio>
              <b-form-radio
                v-model="selectedOrderCount"
                value="1000"
                class="form-radio text-nowrap"
              >{{ isBrandVsRetailerTerms ? 'Existing': 'Subsequent Orders' }} </b-form-radio>
            </div>
          </k-form-group>
        </validation-provider>
        <k-form-group
          class="mt-2"
        >
          <template #label>
            <span> Start Date (Optional) </span>
          </template>
          <k-form-input
            v-model="startDate"
            type="date"
            class="form-input-text"
            :class="{'has-value': !!startDate}"
          />
        </k-form-group>
        <k-form-group
          class="mt-2"
        >
          <template #label>
            <span> End Date (Optional) </span>
          </template>
          <k-form-input
            v-model="endDate"
            type="date"
            class="form-input-text"
            :class="{'has-value': !!endDate}"
          />
        </k-form-group>
        <k-form-group>
          <div
            v-if="isSelfManagedBrand"
            class="pt-3 pb-2 ps-1 d-flex flex-row align-items-center"
          >
            <span>
              <label
                class="custom-checkbox-container"
              >
                <input
                  v-model="isSelfManaged"
                  type="checkbox"
                >
                <span class="checkmark" />
              </label>
            </span>
            <span
              class="ps-4 cursor-pointer"
              @click="isSelfManaged=!isSelfManaged"
            >Enable Kingpin Direct</span>
          </div>
        </k-form-group>
      </div>
      <div class="d-flex align-items-center mt-3">
        <k-button
          variant="info"
          type="submit"
          block
          :disabled="loading || !isInputValid || invalid"
          @click="createRule"
        >
          CREATE
          <loader-icon v-if="loading" />
        </k-button>
        <k-button
          variant="outline-info"
          class="ms-4"
          block
          :disabled="loading"
          @click="$emit('dynamic-emits','close')"
        >
          CANCEL
        </k-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { required } from '@/@core/utils/validations/validations'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { LOGISTICS_TERMS, PAYMENT_TERMS } from '@/constants'
import { walletMixin } from '@/mixins/wallet-mixin'
import store from '@/store'
import { GET_PRICE_BOOK } from '@/store/modules/collection.module'
import { FETCH_USER } from '@/store/modules/user-module'
import { CREATE_WALLET_RULES } from '@/store/modules/wallet.module'
import { KButton, KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import {
  BDropdown,
  BDropdownForm,
  BDropdownItem,
  BFormRadio,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { LoaderIcon } from 'vue-feather-icons'

const { BRAND_ACCESS } = constants

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    FeatherIcon,
    LoaderIcon,
    BFormRadio,
    KButton,
    KFormGroup,
    KFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [walletMixin],
  data() {
    return {
      retailers: [],
      filteredRetailers: [],
      searchRetailer: '',
      selectedRetailer: {
        name: '',
        id: '',
        email: '',
      },
      brands: [],
      filteredBrands: [],
      searchBrand: '',
      selectedBrand: {
        name: '',
        id: '',
        email: '',
      },
      PAYMENT_TERMS,
      LOGISTICS_TERMS,
      commercialTerms: '',
      selectedPaymentTerms: null,
      selectedLogisticsTerms: '',
      endDate: null,
      priceMultiplier: '',
      submit: false,
      selectedOrderCount: null,
      startDate: null,
      loading: false,
      required,
      isSelfManaged: false,
      isSelfManagedBrand: false,
      priceBooks: [],
      priceBookId: null,
    }
  },
  computed: {
    isInputValid() {
      const isFieldsValid = this.selectedLogisticsTerms && !!this.selectedPaymentTerms
      if (this.isBrandTerms) {
        return this.selectedBrand?.id && isFieldsValid
      }
      if (this.isRetailerTerms) {
        return this.selectedRetailer?.id && isFieldsValid
      }
      return this.selectedBrand?.id && this.selectedRetailer?.id && isFieldsValid
    },
  },
  watch: {
    searchRetailer() {
      this.filteredRetailers = this.retailers.filter(retailer => retailer.name.toLowerCase()
        .includes(this.searchRetailer.toLowerCase())
        || retailer.email.includes(this.searchRetailer.toLowerCase()))
    },
    searchBrand() {
      this.filteredBrands = this.brands.filter(brand => brand.name.toLowerCase()
        .includes(this.searchBrand.toLowerCase())
        || brand.email.toLowerCase().includes(this.searchBrand.toLowerCase()))
    },
  },
  emits: ['dynamic-emits'],
  created() {
    this.retailers = store.getters.retailers
    this.filteredRetailers = store.getters.retailers
    this.brands = store.getters.brands
    this.filteredBrands = store.getters.brands
  },
  methods: {
    onSelectBrand(brand) {
      this.selectedBrand = brand
      if (this.isBrandVsRetailerTerms) {
        this.priceBookId = null
        this.getPriceBook(brand.id)
        this.$store.dispatch(FETCH_USER, brand.id).then(res => {
          const resData = res?.data?.data
          this.isSelfManagedBrand = resData?.access?.includes(BRAND_ACCESS.DIRECT) || false
        })
      }
    },
    createRule() {
      this.loading = true
      const payload = {
        paymentTerms: this.selectedPaymentTerms,
        logisticsTerms: LOGISTICS_TERMS[`${this.selectedLogisticsTerms}`],
        orderCount: parseInt(this.selectedOrderCount),
      }
      if (this.isBrandVsRetailerTerms) {
        payload.isSelfManaged = this.isSelfManaged
      }
      if (this.commercialTerms >= 0 && !this.isRetailerTerms) {
        payload.commercialTerms = Number(this.commercialTerms)
      }
      if (this.priceMultiplier > 0) {
        payload.value = Number(this.priceMultiplier)
      }
      if (this.selectedBrand?.id) {
        payload.brandId = this.selectedBrand.id
      }
      if (this.selectedRetailer?.id) {
        payload.retailerId = this.selectedRetailer.id
      }
      if (this.endDate) {
        payload.endTime = new Date(this.endDate)
      }
      if (this.startDate) {
        payload.startTime = new Date(this.startDate)
      }
      if (this.priceBookId) {
        payload.priceBookId = this.priceBookId
      }
      store.dispatch(CREATE_WALLET_RULES, payload)
        .then(res => {
          this.loading = false
          apiToastSuccess(res.data.message)
          this.$emit('dynamic-emits', 'success')
        })
        .catch(err => {
          this.loading = false
          apiToastError(err)
        })
    },
    async getPriceBook(brandId) {
      try {
        if (!brandId) {
          return
        }
        const params = {
          brandId,
        }
        const result = await this.$store.dispatch(GET_PRICE_BOOK, params)
        this.priceBooks = result?.data?.data || []
      }
      catch (err) {
        apiToastError(err)
      }
    },
  },
}
</script>
